import React, { useEffect, useRef } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { convertMFGTS, convertAccessTS, rejectTsqcAPI } from "./api/api";
import { successToaster, errorToaster } from "./helpers/toaster";
function ShowTreatmentPlan(props) {
  const { setStateObject, stateObject, massage } = props;

  const issue = useRef(null);

  const cancelConverting = async (data) => {
    rejectTsqcAPI(data.treatmentSetupsUuid, data.ownerType, {
      tsqcIssuesIdsToBeFixed: massage.data,
    });

    successToaster("TS rejected successfully.");
    setStateObject((prevState) => ({
      ...prevState,
      showTS: false,
    }));
  };

  const updateTreatment = async (data) => {
    setStateObject((prevState) => ({
      ...prevState,
      updateTs: true,
    }));
    data.isEonMFG
      ? convertMFGTS(data.ownerType, data.treatmentSetupsUuid, stateObject.url)
      : convertAccessTS(data.caseId, data.treatmentSetupsUuid, stateObject.url);
    {
      ["SKIP_TSQC", "Approved_TSQC"].includes(massage.data) &&
        rejectTsqcAPI(data.treatmentSetupsUuid, data.ownerType, {
          tsqcIssuesIdsToBeFixed: [],
        });
    }
    successToaster("TS Approved successfully.");
    setStateObject((prevState) => ({
      ...prevState,
      caseId: "",
      uploading: false,
      converting: false,
      showError: false,
      errors: [],
      caseUuid: "",
      treatmentSetups: [],
      treatmentSetupsUuid: "",
      chosenTs: {},
      doctor: {},
      patient: {},
      ownerType: false,
      isEonMFG: false,
      showTS: false,
      updateTs: false,
      key: {},
      url: {},
      showConfirmation: false,
      chosenFile: [],
      xmlFile: [],
    }))
    // setStateObject((prevState) => ({
    //   ...prevState,
    //   updateTs: false,
    //   showTS: false,
    // }));
  };

  useEffect(() => {
    if (["SKIP_TSQC", "Approved_TSQC"].includes(massage.data)) {
      updateTreatment(stateObject);
    }

    if (Array.isArray(massage.data)) {
      cancelConverting(stateObject);
    }
  }, [massage]);

  const toggleTSModal = () => {
    setStateObject((prevState) => ({
      ...prevState,
      showConfirmation: !prevState.showConfirmation,
    }));
  };

  const ToggleEmpty = () => { };

  return (
    <Modal
      className="modalts"
      backdrop={false}
      isOpen={stateObject.showTS}
      toggle={!stateObject.updateTs ? toggleTSModal : ToggleEmpty}
    >
      <ModalHeader
        className="w-100 align-items-center"
        toggle={!stateObject.updateTs ? toggleTSModal : ToggleEmpty}
      >
        <Row className="w-100 align-items-center">
          <Col>TS Viewer</Col>
          <Col className="text-end">
            {console.log({ massage })}
            {/* // if the value is null then it has not been tested by TSQC */}
            {((!massage?.tsqcReportId) || (massage?.tsqcReportId && !massage.hasTsqcReportedIssues)) && (
              <Button
                size="sm"
                className={`h-100 float-right active-btn`}
                onClick={async () => {
                  await updateTreatment(stateObject);
                  setStateObject((prevState) => ({
                    ...prevState,
                    caseId: "",
                    uploading: false,
                    converting: false,
                    showError: false,
                    errors: [],
                    caseUuid: "",
                    treatmentSetups: [],
                    treatmentSetupsUuid: "",
                    chosenTs: {},
                    doctor: {},
                    patient: {},
                    ownerType: false,
                    isEonMFG: false,
                    showTS: false,
                    key: {},
                    url: {},
                    showConfirmation: false,
                    chosenFile: [],
                    xmlFile: [],
                  }));
                }}
              >
                Approve and Update TS
              </Button>
            )}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <iframe

          src={`${process.env.REACT_APP_TP_VIEWER}/?isM=${stateObject.isEonMFG}&treatmentSetupsId=${stateObject?.chosenTs?.id}&isC=true`}
          width={"100%"}
          height={"100%"}
          className="viewer-iframe"
          allowFullScreen
          ref={issue}
          allow="clipboard-read; clipboard-write"
        />
      </ModalBody>
    </Modal>
  );
}

export default ShowTreatmentPlan;
